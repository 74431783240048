<template>
  <v-card class="card-reel">
    <v-img height="220px" :src="srcImage" class="width-card grey darken-4 img-reel">
      <div class="actions-card">
        <v-spacer></v-spacer>
        <v-menu offset-y left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn elevation="1" v-bind="attrs" v-on="on" icon color="primary">
              <v-icon color="#fafafa" size="20">{{ icons.mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click="$emit('edit', item)">
              <v-list-item-title>Editar</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="$emit('remove', item)">
              <v-list-item-title>Eliminar</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-img>
    <div :class="`releases-details`">
      <h3 class="title-releases">
        {{ item.title }}
      </h3>
      <h3 class="date-releases">Publicado: {{ item.date }}</h3>
      <h5 class="description-releases">{{ item.description.substring(0, 200) }}...</h5>
      <div class="btn-container-releases">
        <router-link :to="`/releases/${item.slug}`" class="btn-releases">Ver Comunicado</router-link>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mdiDotsVertical } from '@mdi/js'
export default {
  props: {
    item: Object,
    srcImage: String,
  },
  setup() {
    return {
      icons: {
        mdiDotsVertical,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.card-reel {
  .actions-card {
    position: absolute;
    right: 0;
    top: 0;
  }
  .container-slide {
    position: absolute;
    left: 50%;
    top: 50%;
    max-width: 80%;
    transform: translate(-50%, -50%);
    .notice-details {
      margin: 15px 30px 20px !important;
    }
    @media screen and (max-width: 550px) {
      max-width: 90%;
    }
  }
  .releases-details {
    margin: 15px;
    .title-releases {
      font-size: 18px;
    }
    .description-releases {
      font-size: 12px;
      overflow-wrap: break-word;
      font-weight: 500;
    }
    .date-releases {
      font-size: 12px;
      color: var(--text-gray-2);
      display: flex;
      justify-content: flex-end;
    }
    .btn-container-releases {
      display: flex;
      justify-content: flex-end;
      .btn-releases {
        text-decoration: none;
        font-family: 'Seravek-Bold', 'Segoe UI';
        background: #e83762;
        color: white;
        display: inline-flex;
        align-items: center;
        padding: 7px;
        gap: 5px;
        margin: 20px 0 10px 0;
      }
    }
  }
}
.img-reel::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}
</style>

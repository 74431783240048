<template>
  <v-card-text>
    <v-data-iterator :items="releases" :items-per-page="10" :page="1">
      <template v-slot:default="props">
        <v-row>
          <v-col
            v-for="item in props.items"
            :key="item.id"
            cols="12"
            lg="3"
            md="6"
            sm="12"
          >
            <CardReleases
              :item="item"
              :srcImage="loadImage(item.img_release)"
              @edit="$emit('edit', item)"
              @remove="$emit('remove', item)"
            ></CardReleases>

          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </v-card-text>
</template>

<script>
import CardReleases from "@/components/cards/CardReleases.vue";
import { loadAPIImage } from "@/utils/images";
export default {
  components: {
    CardReleases,
  },
  props: {
    releases: Array,
  },
  methods: {
    loadImage(src) {
      return loadAPIImage(src);
    },
  },
};
</script>

<style lang="scss" scoped></style>

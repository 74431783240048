<template>
  <v-card elevation="0">
    <HeaderView title="Comunicados" @openToAdd="openDialog" :noSearch="true"></HeaderView>
    <ListReleases :releases="releases" @edit="openEdit" @remove="openRemove" />
    <WDialog
      width="500"
      :dialog="dialog"
      :closeDialog="closeDialog"
      :title="titleDialog"
      :subtitle="subtitleDialog"
    >
      <template v-slot:form-dialog>
        <FormReleases
          v-if="dialog"
          :loading="loading"
          :payloadEdited="releasesEdited"
          @beforeUpdate="updateReleases"
          @beforeCreate="createReleases">
        </FormReleases>
      </template>
    </WDialog>
  </v-card>
</template>

<script>
import FormReleases from "@/components/forms/FormReleases.vue";
import ListReleases from "./ListReleases.vue";
import HeaderView from "@/components/HeaderView.vue";
import WDialog from "@/components/dialogs/WDialog.vue";
import useReleases from "@/composables/useReleases";

export default {
  components: {
    FormReleases,
    HeaderView,
    ListReleases,
    WDialog,
  },
  setup() {
    // Composition API
    const {
      dialog,
      releasesEdited,
      releases,
      loading,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      openDialog,
      openEdit,
      closeDialog,
      // crud
      getReleasesList,
      createReleases,
      updateReleases,
      removeReleases,
      openRemove,
    } = useReleases();

    return {
      dialog,
      releasesEdited,
      releases,
      loading,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      openDialog,
      openEdit,
      closeDialog,
      // crud
      getReleasesList,
      createReleases,
      updateReleases,
      removeReleases,
      openRemove
    };
  },
};
</script>

<style lang="scss" scoped></style>

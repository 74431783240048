import {ref, computed} from '@vue/composition-api';
import store from '@/store/index';

const useReleases = () => {
  // base data
  const dialog = ref (false);
  const releasesEdited = ref (null);
  const releases = ref ([]);
  const loading = ref (false);

  // computed properties
  const titleDialog = computed (
    () =>
      releasesEdited.value
        ? 'Editar Comunicado'
        : 'Agregar Comunicado'
  );
  const subtitleDialog = computed (
    () =>
      releasesEdited.value
        ? 'Rellene los campos correctamente para modificar los datos del comunicado'
        : 'Rellene los campos correctamente para agregar un nuevo comunicado'
  );

  // methods
  const openDialog = () => {
    dialog.value = true;
  };
  const openEdit = releases => {
    dialog.value = true;
    releasesEdited.value = {...releases};
  };
  const closeDialog = () => {
    dialog.value = false;
    releasesEdited.value = null;
  };

  // crud
  const getReleasesList = async () => {
    const {data, status} = await store.dispatch ('fetchReleases');
    if (status != 200) return;
    releases.value = data;
  };

  const createReleases = async releases => {
    loading.value = true;
    const {data, status} = await store.dispatch (
      'createReleases',
      releases
    );
    loading.value = false;
    if (status != 200 && status != 201) return;
    await getReleasesList ();
    closeDialog ();
  };

  const updateReleases = async releases => {
    loading.value = true;
    const {data, status} = await store.dispatch (
      'updateReleases',
      releases
    );
    loading.value = false;
    if (status != 200 && status != 201) return;
    await getReleasesList ();
    closeDialog ();
  };

  const removeReleases = async user => {
    const val = await store.dispatch ('generateConfirmDialog', {});
    if (!val) return;
    const {data, status} = await store.dispatch ('removeReleases', user);
    if (status != 200 && status != 201 && status != 204) return;
    await getReleasesList ();
  };

  getReleasesList ();

  return {
    dialog,
    releasesEdited,
    releases,
    loading,
    //crud
    createReleases,
    updateReleases,
    openRemove: removeReleases,
    // methods
    openDialog,
    openEdit,
    closeDialog,
    // computed
    titleDialog,
    subtitleDialog,
  };
};

export default useReleases;

<template>
  <FormTemplate>
    <template v-slot:form-body>
      <v-form ref="formReleases" @submit.prevent="saveForm">
        <v-row>
          <v-col cols="12" md="3">
            <label for="date">Fecha de publicacion</label>
          </v-col>
          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              type="date"
              id="date"
              v-model="payload['date']"
              outlined
              dense
              placeholder="07 de diciembre del 2021"
              :autocomplete="autocomplete"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <label for="title">Titulo</label>
          </v-col>
          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              id="title"
              v-model="payload['title']"
              outlined
              dense
              placeholder="Subasta Pública 2022"
              :autocomplete="autocomplete"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <label for="description">Descripción</label>
          </v-col>
          <v-col cols="12" class="pb-0">
            <v-textarea
              id="description"
              v-model="payload['description']"
              outlined
              dense
              :autocomplete="autocomplete"
              :rules="required"
              rows="6"
            ></v-textarea>
          </v-col>

          <v-col cols="12" md="3">
            <label for="file-press-notes">Imagen</label>
          </v-col>
          <v-col cols="12" md="9" class="pb-0">
            <v-file-input
              id="file-press-notes"
              v-model="images"
              outlined
              dense
              placeholder="Selecciona una imagen"
              :autocomplete="autocomplete"
              :rules="payloadEdited ? [] : required"
              chips
            ></v-file-input>
          </v-col>

          <v-col offset-md="3" cols="9">
            <v-btn type="submit" :disabled="loading" :loading="loading" color="primary"> Guardar </v-btn>
            <v-btn type="reset" @click="reset" class="mx-2" outlined> Limpiar </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </FormTemplate>
</template>

<script>
import FormTemplate from '@/components/forms/FormTemplate.vue'
export default {
  components: {
    FormTemplate,
  },
  props: {
    payloadEdited: Object,
    loading: Boolean,
  },
  data() {
    return {
      required: [v => !!v || 'Requerido'],
      autocomplete: 'off',
      isEdited: this.payloadEdited ? true : false,
      images: null,
      payload: this.payloadEdited ? { ...this.payloadEdited } : {},
    }
  },
  methods: {
    validateForm() {
      return this.$refs.formReleases.validate()
    },
    reset() {
      this.payload['title'] = ''
      this.payload['description'] = ''
      this.images = null
      this.payload['date'] = ''
      this.$refs.formReleases.resetValidation()
    },
    saveForm() {
      if (!this.validateForm()) return
      const formData = new FormData()
      formData.append('title', this.payload['title'])
      formData.append('description', this.payload['description'])
      formData.append('date', this.payload.date)
      if (this.images) {
        formData.append('file_release', this.images)
      }
      const DTO = {
        id: this.payload.id,
        formData,
      }
      // // console.log(DTO)
      if (this.isEdited) {
        this.$emit('beforeUpdate', DTO)
      } else {
        this.$emit('beforeCreate', DTO)
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
